.level_selector[data-level-selector] {
  float: right;
  position: relative;
  height: 100%;

  button {
    background-color: transparent;
    color: #fff;
    font-family: Roboto, "Open Sans", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    border: none;
    font-size: 12px;
    height: 100%;

    &:hover {
      color: #c9c9c9;
    }

    &.changing {
      -webkit-animation: pulse 0.5s infinite alternate;
    }
  }

  & > ul {
    overflow-x: hidden;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    bottom: 100%;
    display: none;
    background-color: rgba(28, 28, 28, 0.9);
    white-space: nowrap;
  }

  li {
    font-size: 12px;
    color: #eee;

    &[data-title] {
      background-color: #333;
      padding: 8px 25px;
    }

    a {
      color: #eee;
      padding: 5px 18px;
      display: block;
      text-decoration: none;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    &.current a {
      color: #2ecc71;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    color: #fff;
  }
  50% {
    color: #ff0101;
  }
  100% {
    color: #b80000;
  }
}
