body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #172962, #172962);
  background-size: 400% 400%;
  color: #fff;
}
hr {
  background: #fff;
  opacity: 0.2;
}
body.animation {
  width: 100wh;
  height: 90vh;
  color: #fff;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#root {
  height: 100%;
}

.ovnLogo img {
  width: 200px;
  opacity: 0.5;
}

.bigLogo {
  fill: #fff;
}

.homeWrap {
  text-align: center;
  opacity: 0.5;
}

.pageWrap {
  margin: 4% 0;
  text-align: center;
}

.pageWrap .card {
  background: rgba(255, 255, 255, 0.9) none repeat scroll 0% 0%;
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* text-align: left; */
}

.pageWrap .card .card {
  background: #e4f1f4 none repeat scroll 0% 0%;
}

body {
  background: #e8e8e8 url(//s1.myovn.com/images/_bg/bg1.jpg) no-repeat center
    center fixed;
}

.pageWrap h3 {
  margin: 0;
}

.disclaimer {
  text-align: left;
}

.pageWrap h6 {
  text-align: center !important;
}

.pageWrap .card.chatWrapper {
  text-align: right;
  padding-top: 0;
}

.gala {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.chatMsg {
  /* padding-right: 20px;
  /* max-height: 300px;
  height: 300px;
  overflow-y: scroll; */

  overflow-x: hidden;
}
.chatItem {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  border: none;
  padding: 8px;
  margin-top: 14px;
  margin-right: 20px;
  border-radius: 3px;
  display: inline-block;
  min-width: 100px;
  /* white-space: pre; */
}

.chatItemExtra {
  font-size: 0.7em;
  display: block;
  opacity: 0.6;
  margin-right: 20px;
}

.chatAdd textarea,
.chatAdd input,
.chatAdd textarea:focus,
.chatAdd input:focus {
  background: #fff;
  border: 2px solid #fff;
}

.chatAdd textarea::-webkit-input-placeholder {
  color: #999;
}

.chatAdd textarea:-ms-input-placeholder {
  color: #999;
}

.chatAdd textarea::-ms-input-placeholder {
  color: #999;
}

.chatAdd textarea::placeholder {
  color: #999;
}
.chatButtonAdd {
  margin-top: 8px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.btn.chatEditBtn {
  background: none;
  color: #545454;
}

.btn.chatEditBtn:hover {
  background: none;
  color: #000;
}

.chatButtonAdd:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}

.alignCenter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.searchBarWrap {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 1rem;
  text-align: left;
}

.searchBarWrap .btn {
  margin-top: 0;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  border: none;
}

.searchBarWrap input {
  opacity: 0.8;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  border: none;
}

label {
  text-align: left;
  width: 100%;
}

.form-control {
  background-color: #e4f1f4;
  border: none;
  color: #3374b8;
}

.form-control:focus,
.btn:focus {
  border: none;
  background-color: #e4f1f4;
  border: 1px solid #3374b8;
  color: #3374b8;
  outline: 0;
  box-shadow: none;
}

.pageWrap .card.mediaItem {
  padding: 0;
  margin: 0.5rem 0;
  cursor: pointer;
}

.pageWrap .card.mediaItem:hover {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0%;
}

.mediaTitle {
  font-size: 12px;
  margin: 0.5rem;
  line-height: 1rem;
  min-height: 2.2rem;
  max-height: 2.2rem;
  overflow: hidden;
}

.currentVideo {
  position: absolute;
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%;
  padding: 4px;
  font-size: 8px;
  top: 0.4rem;
  right: 0.4rem;
  border-radius: 2px;
  font-weight: bold;
}

.liveVideo {
  position: absolute;
  background: rgba(228, 37, 37, 0.8) none repeat scroll 0% 0%;
  padding: 4px;
  font-size: 8px;
  top: 0.4rem;
  left: 0.4rem;
  border-radius: 2px;
  font-weight: bold;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

.paginationWrap {
  margin-top: 1rem;
  width: 100%;
}
.page-link {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  border: none;
  color: #fff;
}

.page-link:hover {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0%;
  color: #fff;
}

.page-item .active.page-link {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0%;
  color: #fff;
}

.page-item.disabled .page-link {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  cursor: default;
}

.card .disclaimer {
  max-height: 200px;

  padding: 10px;
  font-size: 0.7em;
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: content-box;
  box-sizing: initial;
  white-space: pre-wrap;
  display: block;
}

.chiller_cb {
  position: relative;
  height: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.chiller_cb input {
  display: none;
}
.chiller_cb input:checked ~ span {
  background: #3374b9;
  border-color: #3374b9;
}
.chiller_cb input:checked ~ span:before {
  width: 1rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.3s;
}
.chiller_cb input:checked ~ span:after {
  width: 0.4rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.2s;
}
.chiller_cb input:disabled ~ span {
  background: #ececec;
  border-color: #dcdcdc;
}
.chiller_cb input:disabled ~ label {
  color: #dcdcdc;
}
.chiller_cb input:disabled ~ label:hover {
  cursor: default;
}
.chiller_cb label {
  padding-left: 2rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-bottom: 0;
}
.chiller_cb span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #e4f1f4;
  background: #e4f1f4;
  position: absolute;
  left: 0;
  transition: all 0.2s;
  z-index: 1;
  box-sizing: content-box;
  border-radius: 4px;
}
.chiller_cb span:before {
  -webkit-transform: rotate(-55deg);
          transform: rotate(-55deg);
  top: 1rem;
  left: 0.37rem;
}
.chiller_cb span:after {
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  bottom: 0.35rem;
  left: 0.2rem;
}

.chatText {
  display: -webkit-flex;
  display: flex;
  opacity: 0.6;
  -webkit-align-items: center;
          align-items: center;
}

.moderateRow {
  /* background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%; */
  margin: 2px;
}

.moderateRow .card {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.btn {
  background-color: #3374b8;
  color: #fff;
  cursor: pointer;
  opacity: 0.9;
}

.btn:hover {
  background-color: #3374b8;
  color: #fff;
  cursor: pointer;
  opacity: 1;
}

.btn:disabled,
.btn:disabled:hover {
  /* opacity: 0.3; */
  background-color: #e4f1f4;
}

.chatName {
  text-align: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: baseline;
          align-items: baseline;
  opacity: 0.6;
}

.media-control-notransition {
  transition: none !important; }

.media-control[data-media-control] {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none; }
  .media-control[data-media-control].dragging {
    pointer-events: auto;
    cursor: -webkit-grabbing !important;
    cursor: grabbing !important; }
    .media-control[data-media-control].dragging * {
      cursor: -webkit-grabbing !important;
      cursor: grabbing !important; }
  .media-control[data-media-control] .media-control-background[data-background] {
    position: absolute;
    height: 40%;
    width: 100%;
    bottom: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    transition: opacity 0.6s ease-out; }
  .media-control[data-media-control] .media-control-icon {
    line-height: 0;
    letter-spacing: 0;
    speak: none;
    color: #fff;
    opacity: 0.5;
    vertical-align: middle;
    text-align: left;
    transition: all 0.1s ease; }
  .media-control[data-media-control] .media-control-icon:hover {
    color: white;
    opacity: 0.75;
    text-shadow: rgba(255, 255, 255, 0.8) 0 0 5px; }
  .media-control[data-media-control].media-control-hide .media-control-background[data-background] {
    opacity: 0; }
  .media-control[data-media-control].media-control-hide .media-control-layer[data-controls] {
    bottom: -50px; }
    .media-control[data-media-control].media-control-hide .media-control-layer[data-controls] .bar-container[data-seekbar] .bar-scrubber[data-seekbar] {
      opacity: 0; }
  .media-control[data-media-control] .media-control-layer[data-controls] {
    position: absolute;
    bottom: 7px;
    width: 100%;
    height: 32px;
    font-size: 0;
    vertical-align: middle;
    pointer-events: auto;
    transition: bottom 0.4s ease-out; }
    .media-control[data-media-control] .media-control-layer[data-controls] .media-control-left-panel[data-media-control] {
      position: absolute;
      top: 0;
      left: 4px;
      height: 100%; }
    .media-control[data-media-control] .media-control-layer[data-controls] .media-control-center-panel[data-media-control] {
      height: 100%;
      text-align: center;
      line-height: 32px; }
    .media-control[data-media-control] .media-control-layer[data-controls] .media-control-right-panel[data-media-control] {
      position: absolute;
      top: 0;
      right: 4px;
      height: 100%; }
    .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button {
      background-color: transparent;
      border: 0;
      margin: 0 6px;
      padding: 0;
      cursor: pointer;
      display: inline-block;
      width: 32px;
      height: 100%; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button svg {
        width: 100%;
        height: 22px; }
        .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button svg path {
          fill: white; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button:focus {
        outline: none; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-play] {
        float: left;
        height: 100%; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-pause] {
        float: left;
        height: 100%; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-stop] {
        float: left;
        height: 100%; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-fullscreen] {
        float: right;
        background-color: transparent;
        border: 0;
        height: 100%; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-hd-indicator] {
        background-color: transparent;
        border: 0;
        cursor: default;
        display: none;
        float: right;
        height: 100%; }
        .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-hd-indicator].enabled {
          display: block;
          opacity: 1; }
          .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-hd-indicator].enabled:hover {
            opacity: 1;
            text-shadow: none; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-playpause] {
        float: left; }
      .media-control[data-media-control] .media-control-layer[data-controls] button.media-control-button[data-playstop] {
        float: left; }
    .media-control[data-media-control] .media-control-layer[data-controls] .media-control-indicator[data-position], .media-control[data-media-control] .media-control-layer[data-controls] .media-control-indicator[data-duration] {
      display: inline-block;
      font-size: 10px;
      color: white;
      cursor: default;
      line-height: 32px;
      position: relative; }
    .media-control[data-media-control] .media-control-layer[data-controls] .media-control-indicator[data-position] {
      margin: 0 6px 0 7px; }
    .media-control[data-media-control] .media-control-layer[data-controls] .media-control-indicator[data-duration] {
      color: rgba(255, 255, 255, 0.5);
      margin-right: 6px; }
      .media-control[data-media-control] .media-control-layer[data-controls] .media-control-indicator[data-duration]:before {
        content: "|";
        margin-right: 7px; }
    .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar] {
      position: absolute;
      top: -20px;
      left: 0;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 30px);
      height: 25px;
      cursor: pointer;
      margin: 0 15px; }
      .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar] .bar-background[data-seekbar] {
        width: 100%;
        height: 8px;
        position: relative;
        top: 12px;
        background-color: #666666;
        border-radius: 4px; }
        .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar] .bar-background[data-seekbar] .bar-fill-1[data-seekbar] {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: #c2c2c2;
          transition: all 0.1s ease-out;
          border-radius: 4px; }
        .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar] .bar-background[data-seekbar] .bar-fill-2[data-seekbar] {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: #005aff;
          transition: all 0.1s ease-out;
          border-radius: 4px; }
        .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar] .bar-background[data-seekbar] .bar-hover[data-seekbar] {
          opacity: 0;
          position: absolute;
          top: 0px;
          border-radius: 4px;
          width: 6px;
          height: 6px;
          border-radius: 10px;
          box-shadow: 0 0 0 6px white;
          background-color: white;
          transition: opacity 0.1s ease; }
      .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar]:hover .bar-background[data-seekbar] .bar-hover[data-seekbar] {
        opacity: 1; }
      .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar].seek-disabled {
        cursor: default; }
        .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar].seek-disabled:hover .bar-background[data-seekbar] .bar-hover[data-seekbar] {
          opacity: 0; }
      .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar] .bar-scrubber[data-seekbar] {
        position: absolute;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        top: 4px;
        left: 0;
        width: 20px;
        height: 20px;
        opacity: 1;
        transition: all 0.1s ease-out; }
        .media-control[data-media-control] .media-control-layer[data-controls] .bar-container[data-seekbar] .bar-scrubber[data-seekbar] .bar-scrubber-icon[data-seekbar] {
          position: absolute;
          left: 6px;
          top: 8px;
          width: 6px;
          height: 6px;
          border-radius: 10px;
          box-shadow: 0 0 0 6px white;
          background-color: white; }
    .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] {
      float: right;
      display: inline-block;
      height: 32px;
      cursor: pointer;
      margin: 0 6px;
      box-sizing: border-box; }
      .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .drawer-icon-container[data-volume] {
        float: left;
        bottom: 0; }
        .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .drawer-icon-container[data-volume] .drawer-icon[data-volume] {
          background-color: transparent;
          border: 0;
          box-sizing: content-box;
          width: 32px;
          height: 32px;
          opacity: 0.5; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .drawer-icon-container[data-volume] .drawer-icon[data-volume]:hover {
            opacity: 0.75; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .drawer-icon-container[data-volume] .drawer-icon[data-volume] svg {
            height: 24px;
            position: relative;
            top: 3px; }
            .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .drawer-icon-container[data-volume] .drawer-icon[data-volume] svg path {
              fill: white; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .drawer-icon-container[data-volume] .drawer-icon[data-volume].muted svg {
            margin-left: 2px; }
      .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] {
        float: left;
        position: relative;
        overflow: hidden;
        top: 6px;
        width: 42px;
        height: 18px;
        padding: 3px 0;
        transition: width 0.2s ease-out; }
        .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .bar-background[data-volume] {
          height: 1px;
          position: relative;
          top: 7px;
          margin: 0 3px;
          background-color: #666666; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .bar-background[data-volume] .bar-fill-1[data-volume] {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: #c2c2c2;
            transition: all 0.1s ease-out; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .bar-background[data-volume] .bar-fill-2[data-volume] {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: #005aff;
            transition: all 0.1s ease-out; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .bar-background[data-volume] .bar-hover[data-volume] {
            opacity: 0;
            position: absolute;
            top: -3px;
            width: 5px;
            height: 7px;
            background-color: rgba(255, 255, 255, 0.5);
            transition: opacity 0.1s ease; }
        .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .bar-scrubber[data-volume] {
          position: absolute;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          top: 0px;
          left: 0;
          width: 20px;
          height: 20px;
          opacity: 1;
          transition: all 0.1s ease-out; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .bar-scrubber[data-volume] .bar-scrubber-icon[data-volume] {
            position: absolute;
            left: 6px;
            top: 6px;
            width: 8px;
            height: 8px;
            border-radius: 10px;
            box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
            background-color: white; }
        .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .segmented-bar-element[data-volume] {
          float: left;
          width: 4px;
          padding-left: 2px;
          height: 12px;
          opacity: 0.5;
          box-shadow: inset 2px 0 0 white;
          transition: -webkit-transform 0.2s ease-out;
          transition: transform 0.2s ease-out;
          transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .segmented-bar-element[data-volume].fill {
            box-shadow: inset 2px 0 0 #fff;
            opacity: 1; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .segmented-bar-element[data-volume]:nth-of-type(1) {
            padding-left: 0; }
          .media-control[data-media-control] .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume] .segmented-bar-element[data-volume]:hover {
            -webkit-transform: scaleY(1.5);
                    transform: scaleY(1.5); }
  .media-control[data-media-control].w320 .media-control-layer[data-controls] .drawer-container[data-volume] .bar-container[data-volume].volume-bar-hide {
    width: 0;
    height: 12px;
    top: 9px;
    padding: 0; }

.live-program[data-media-control] {
  position: absolute;
  left: 100px;
  bottom: 8px;
  display: inline-block;
  width: 50%;
  height: 16px; }

.live-program[data-media-control] .live-program-start[data-media-control] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40px;
  height: 100%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 16px; }

.live-program[data-media-control] .live-program-end[data-media-control] {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 100%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 16px; }

.live-program[data-media-control] .live-program-bar[data-media-control] {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 5px 40px; }

.live-program[data-media-control]
.live-program-bar[data-media-control]
.live-program-progress-bar[data-media-control] {
  position: relative;
  width: 100%;
  height: 100%; }

.live-program[data-media-control]
.live-program-bar[data-media-control]
.live-program-progress-bar[data-media-control]
.live-program-progress-bar-bg[data-media-control] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #c2c2c2; }

.live-program[data-media-control]
.live-program-bar[data-media-control]
.live-program-progress-bar[data-media-control]
.live-program-progress-bar-fill[data-media-control] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 6px;
  background-color: #ff0101; }

.live-views[data-media-control] {
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #fff;
  line-height: 32px;
  font-size: 10px;
  font-weight: bold;
  margin-left: 6px; }
  .live-views[data-media-control] .eye {
    margin: 9px 6px 0 6px;
    width: 13px;
    height: 13px;
    border: solid 1px #fff;
    border-radius: 75% 15%;
    position: relative;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    float: left;
    opacity: 0.7; }
  .live-views[data-media-control] .eye:before {
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border: solid 1px #fff;
    border-radius: 50%;
    left: 3px;
    top: 3px; }
  .live-views[data-media-control] .live-views-count {
    opacity: 0.7; }

.level_selector[data-level-selector] {
  float: right;
  position: relative;
  height: 100%; }
  .level_selector[data-level-selector] button {
    background-color: transparent;
    color: #fff;
    font-family: Roboto, "Open Sans", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    border: none;
    font-size: 12px;
    height: 100%; }
    .level_selector[data-level-selector] button:hover {
      color: #c9c9c9; }
    .level_selector[data-level-selector] button.changing {
      -webkit-animation: pulse 0.5s infinite alternate; }
  .level_selector[data-level-selector] > ul {
    overflow-x: hidden;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    bottom: 100%;
    display: none;
    background-color: rgba(28, 28, 28, 0.9);
    white-space: nowrap; }
  .level_selector[data-level-selector] li {
    font-size: 12px;
    color: #eee; }
    .level_selector[data-level-selector] li[data-title] {
      background-color: #333;
      padding: 8px 25px; }
    .level_selector[data-level-selector] li a {
      color: #eee;
      padding: 5px 18px;
      display: block;
      text-decoration: none; }
      .level_selector[data-level-selector] li a:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff; }
        .level_selector[data-level-selector] li a:hover a {
          color: #fff;
          text-decoration: none; }
    .level_selector[data-level-selector] li.current a {
      color: #2ecc71; }

@-webkit-keyframes pulse {
  0% {
    color: #fff; }
  50% {
    color: #ff0101; }
  100% {
    color: #b80000; } }

.clappr-panel-logo {
  float: right;
  width: 60px;
  height: 100%;
  position: relative;
  background: transparent;
}

/* .clappr-panel-logo div {
  width: 100%;
  height: 100%;
  float: right;
} */

.clappr-panel-logo div img {
  position: absolute;
  top: 2px;
  right: 15px;
  width: 50px;
  height: 100%;
  opacity: 0.3;
  transition: opacity 0.25s, -webkit-transform 0.25s;
  transition: opacity 0.25s, transform 0.25s;
  transition: opacity 0.25s, transform 0.25s, -webkit-transform 0.25s;
}

.clappr-panel-logo div img:hover {
  opacity: 0.7;
}

.clappr-panel-logo div img:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

