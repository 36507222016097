.live-program[data-media-control] {
  position: absolute;
  left: 100px;
  bottom: 8px;
  display: inline-block;
  width: 50%;
  height: 16px;
}

.live-program[data-media-control] .live-program-start[data-media-control] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40px;
  height: 100%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.live-program[data-media-control] .live-program-end[data-media-control] {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 100%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.live-program[data-media-control] .live-program-bar[data-media-control] {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 5px 40px;
}

.live-program[data-media-control]
  .live-program-bar[data-media-control]
  .live-program-progress-bar[data-media-control] {
  position: relative;
  width: 100%;
  height: 100%;
}

.live-program[data-media-control]
  .live-program-bar[data-media-control]
  .live-program-progress-bar[data-media-control]
  .live-program-progress-bar-bg[data-media-control] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #c2c2c2;
}

.live-program[data-media-control]
  .live-program-bar[data-media-control]
  .live-program-progress-bar[data-media-control]
  .live-program-progress-bar-fill[data-media-control] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 6px;
  background-color: #ff0101;
}
