.clappr-panel-logo {
  float: right;
  width: 60px;
  height: 100%;
  position: relative;
  background: transparent;
}

/* .clappr-panel-logo div {
  width: 100%;
  height: 100%;
  float: right;
} */

.clappr-panel-logo div img {
  position: absolute;
  top: 2px;
  right: 15px;
  width: 50px;
  height: 100%;
  opacity: 0.3;
  transition: opacity 0.25s, transform 0.25s;
}

.clappr-panel-logo div img:hover {
  opacity: 0.7;
}

.clappr-panel-logo div img:active {
  transform: scale(0.8);
}
