.ovnLogo img {
  width: 200px;
  opacity: 0.5;
}

.bigLogo {
  fill: #fff;
}

.homeWrap {
  text-align: center;
  opacity: 0.5;
}
