.pageWrap {
  margin: 4% 0;
  text-align: center;
}

.pageWrap .card {
  background: rgba(255, 255, 255, 0.9) none repeat scroll 0% 0%;
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* text-align: left; */
}

.pageWrap .card .card {
  background: #e4f1f4 none repeat scroll 0% 0%;
}

body {
  background: #e8e8e8 url(//s1.myovn.com/images/_bg/bg1.jpg) no-repeat center
    center fixed;
}

.pageWrap h3 {
  margin: 0;
}

.disclaimer {
  text-align: left;
}

.pageWrap h6 {
  text-align: center !important;
}

.pageWrap .card.chatWrapper {
  text-align: right;
  padding-top: 0;
}

.gala {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatMsg {
  /* padding-right: 20px;
  /* max-height: 300px;
  height: 300px;
  overflow-y: scroll; */

  overflow-x: hidden;
}
.chatItem {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  border: none;
  padding: 8px;
  margin-top: 14px;
  margin-right: 20px;
  border-radius: 3px;
  display: inline-block;
  min-width: 100px;
  /* white-space: pre; */
}

.chatItemExtra {
  font-size: 0.7em;
  display: block;
  opacity: 0.6;
  margin-right: 20px;
}

.chatAdd textarea,
.chatAdd input,
.chatAdd textarea:focus,
.chatAdd input:focus {
  background: #fff;
  border: 2px solid #fff;
}

.chatAdd textarea::placeholder {
  color: #999;
}
.chatButtonAdd {
  margin-top: 8px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.btn.chatEditBtn {
  background: none;
  color: #545454;
}

.btn.chatEditBtn:hover {
  background: none;
  color: #000;
}

.chatButtonAdd:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBarWrap {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 1rem;
  text-align: left;
}

.searchBarWrap .btn {
  margin-top: 0;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  border: none;
}

.searchBarWrap input {
  opacity: 0.8;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  border: none;
}

label {
  text-align: left;
  width: 100%;
}

.form-control {
  background-color: #e4f1f4;
  border: none;
  color: #3374b8;
}

.form-control:focus,
.btn:focus {
  border: none;
  background-color: #e4f1f4;
  border: 1px solid #3374b8;
  color: #3374b8;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pageWrap .card.mediaItem {
  padding: 0;
  margin: 0.5rem 0;
  cursor: pointer;
}

.pageWrap .card.mediaItem:hover {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0%;
}

.mediaTitle {
  font-size: 12px;
  margin: 0.5rem;
  line-height: 1rem;
  min-height: 2.2rem;
  max-height: 2.2rem;
  overflow: hidden;
}

.currentVideo {
  position: absolute;
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%;
  padding: 4px;
  font-size: 8px;
  top: 0.4rem;
  right: 0.4rem;
  border-radius: 2px;
  font-weight: bold;
}

.liveVideo {
  position: absolute;
  background: rgba(228, 37, 37, 0.8) none repeat scroll 0% 0%;
  padding: 4px;
  font-size: 8px;
  top: 0.4rem;
  left: 0.4rem;
  border-radius: 2px;
  font-weight: bold;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

.paginationWrap {
  margin-top: 1rem;
  width: 100%;
}
.page-link {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  border: none;
  color: #fff;
}

.page-link:hover {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0%;
  color: #fff;
}

.page-item .active.page-link {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0%;
  color: #fff;
}

.page-item.disabled .page-link {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  cursor: default;
}

.card .disclaimer {
  max-height: 200px;

  padding: 10px;
  font-size: 0.7em;
  margin-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: initial;
  white-space: pre-wrap;
  display: block;
}

.chiller_cb {
  position: relative;
  height: 2rem;
  display: flex;
  align-items: center;
}
.chiller_cb input {
  display: none;
}
.chiller_cb input:checked ~ span {
  background: #3374b9;
  border-color: #3374b9;
}
.chiller_cb input:checked ~ span:before {
  width: 1rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.3s;
}
.chiller_cb input:checked ~ span:after {
  width: 0.4rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.2s;
}
.chiller_cb input:disabled ~ span {
  background: #ececec;
  border-color: #dcdcdc;
}
.chiller_cb input:disabled ~ label {
  color: #dcdcdc;
}
.chiller_cb input:disabled ~ label:hover {
  cursor: default;
}
.chiller_cb label {
  padding-left: 2rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-bottom: 0;
}
.chiller_cb span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #e4f1f4;
  background: #e4f1f4;
  position: absolute;
  left: 0;
  transition: all 0.2s;
  z-index: 1;
  box-sizing: content-box;
  border-radius: 4px;
}
.chiller_cb span:before {
  transform: rotate(-55deg);
  top: 1rem;
  left: 0.37rem;
}
.chiller_cb span:after {
  transform: rotate(35deg);
  bottom: 0.35rem;
  left: 0.2rem;
}

.chatText {
  display: flex;
  opacity: 0.6;
  align-items: center;
}

.moderateRow {
  /* background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%; */
  margin: 2px;
}

.moderateRow .card {
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: #3374b8;
  color: #fff;
  cursor: pointer;
  opacity: 0.9;
}

.btn:hover {
  background-color: #3374b8;
  color: #fff;
  cursor: pointer;
  opacity: 1;
}

.btn:disabled,
.btn:disabled:hover {
  /* opacity: 0.3; */
  background-color: #e4f1f4;
}

.chatName {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  opacity: 0.6;
}
