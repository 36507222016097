.live-views[data-media-control] {
  display: inline-flex;
  //   float: left;
  color: #fff;
  line-height: 32px;
  font-size: 10px;
  font-weight: bold;
  margin-left: 6px;

  .eye {
    margin: 9px 6px 0 6px;
    width: 13px;
    height: 13px;
    border: solid 1px #fff;
    border-radius: 75% 15%;
    position: relative;
    transform: rotate(45deg);
    float: left;
    opacity: 0.7;
  }
  .eye:before {
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border: solid 1px #fff;
    border-radius: 50%;
    left: 3px;
    top: 3px;
  }

  .live-views-count {
    opacity: 0.7;
  }
  //   .live-views-count:before {
  //     content: "";
  //     display: inline-block;
  //     position: relative;
  //     width: 7px;
  //     height: 7px;
  //     border-radius: 3.5px;
  //     margin-right: 3.5px;
  //     background-color: #fff;
  //   }
}
